import { reqAppInfoMsg, appVersion, enableEncryption, encryptionKey, localStorageKeys } from '../../common/constants'
import { commonErrorHandler2 } from '../../actions/common'
import {getItemByKey} from '../../../src/common/utils'

var CryptoJS = require('crypto-js');

var wordArray = CryptoJS.enc.Utf8.parse(encryptionKey);
var skey = CryptoJS.enc.Base64.stringify(wordArray);

var key = CryptoJS.enc.Base64.parse(skey);
var iv = CryptoJS.enc.Base64.parse(skey);
var count = 0;
//=================================================================
// returns a promise 
export const httpPost = function (url, data, dontshowerror) {

  console.log("[REQUEST] " + url);
  console.log(data);
  if (data) {
    data.request.app_version = appVersion;
  }
  else {
    data = {};
  }

  var reqcontent = "";

  if (enableEncryption) {
    reqcontent = encrypt(data);
    console.log(reqcontent);
  }
  else {
    reqcontent = JSON.stringify(data);
  }

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    // body: data ? JSON.stringify(data) : JSON.stringify({})
    body: reqcontent
  })
    .then(checkStatus)
    .then(getBody)
    .then(parseJSON)
    .then(checkInfoMsg)
    .catch(error => {
      //check for app level exception
      if (!dontshowerror && error.infoID === 'EGN006') {
        commonErrorHandler2(error.message);
      }
      throw error;
    })
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    console.log("[ERROR] ");
    console.log(response.statusText);
    var error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

function parseJSON(body) {

  if (enableEncryption) {
    var bytes = CryptoJS.AES.decrypt(body, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv });
    var decrypted = bytes.toString(CryptoJS.enc.Utf8);

    console.log("[RESPONSE] ");
    console.log(decrypted);
    return JSON.parse(decrypted);
  }
  else {
    return body;
  }

}

export function encrypt(data) {
  var edata = CryptoJS.AES.encrypt(JSON.stringify(data), key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv });
  var b64 = edata.ciphertext.toString(CryptoJS.enc.Base64);
  console.log(b64);
  return b64;
}

export function encryptUrl(data, key) {
  var encryptedBase64Key = key;
  var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
  var encryptedData = null;
  {
    encryptedData = CryptoJS.AES.encrypt(data, parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encryptedData;
  }
}


function getBody(response) {
  if (enableEncryption) {
    return response.text();
  }
  else {
    var resp = response.json();

    console.log("[RESPONSE] ");
    console.log(resp);
    return resp;
  }
}

export function serialiseForm(id) {
  var formElement = document.getElementById(id),
    inputElements = formElement.getElementsByTagName("input"),
    jsonObject = "";
  console.log(formElement);
  for (var i = 0; i < inputElements.length; i++) {
    var inputElement = inputElements[i];
    jsonObject += inputElement.name + "=" + inputElement.value + "&";
  }
  console.log(jsonObject.substring(0, jsonObject.length - 1));
  return jsonObject.substring(0, jsonObject.length - 1);
}

export function generateRandomString(anysize) {
  // var anysize = 32;//the size of string 
  var charset = "abcdefghijklmnopqrstuvwxyz"; //from where to create
  var result = "";
  for (var i = 0; i < anysize; i++)
    result += charset[Math.floor(Math.random() * charset.length)];
  console.log(result);
  return result;
}



export function checkenableEncrypt(data) {
  if (enableEncryption) {
    return encrypt(data);
  } else {
    return JSON.stringify(data);
  }
}


export function reloadWhenSymbolChange(){
  var holdings = null;
  window.setTimeout(() => {
    var holdingData = getItemByKey(localStorageKeys.CLIENT_HOLDINGS);
      // holdings = holdingData !== undefined && holdingData !== null ? JSON.parse(getItemByKey(localStorageKeys.CLIENT_HOLDINGS)): null;
      console.log(holdings);
      if ((holdings !== undefined && holdings !== null) && (document.getElementById("nonpoa") !== null)) {
          if (holdings.nonPoaQty == "0") {
            if(document.getElementById("nonpoa").style.display === "inline-flex" && count === 0){
              if(document.getElementsByClassName("toggle-button")[4].innerText  === "IN DP"){
              document.getElementsByClassName("toggle-button")[4].click();
              count = 1;
            }
          }
              document.getElementById("nonpoa").style = "display:none";
              document.getElementById("edisMessage").style = "display:none";
          }
          else {
            count = 0;
              document.getElementById("nonpoa").style = "display:inline-flex; font-size: 10px";
          }

         
      }
  }, 500);
}

function checkInfoMsg(response) {
  console.log(response);
  var cookies = document.cookie.split(';');
  for(var i =0; i < cookies.length; i++){
    if(cookies[i].substring(0,6) === "userId"){
      document.cookie = cookies[i]+"; secure;";
    }
  }
    if(response.response.data.appkey !== undefined){
     window.sessionStorage.removeItem(localStorageKeys.PIB_APP_KEY);
     window.sessionStorage.setItem(localStorageKeys.PIB_APP_KEY, checkenableEncrypt(response.response.data.appkey));
    
    }
    if(window.location.href.indexOf("/home/ipo") != -1){
      window.sessionStorage.setItem(localStorageKeys.PIB_APP_KEY, "");
    }
    if(window.location.href.indexOf("/home/ExchangePledge") != -1){
      window.sessionStorage.setItem(localStorageKeys.PIB_APP_KEY, "");
    }
  if (response.response.data.holdings !== undefined) {
    window.sessionStorage.removeItem(localStorageKeys.CLIENT_HOLDINGS);
    window.sessionStorage.setItem(localStorageKeys.CLIENT_HOLDINGS, checkenableEncrypt(response.response.data.holdings.cash));
    reloadWhenSymbolChange();
  }

  if (response.response.data.nonPoaDemats !== undefined && response.response.data.nonPoaDemats.nonPoaFlag !== "false") {
    var nonPoaData = response.response.data.nonPoaDemats;
    if (nonPoaData.demats.length > 0) {
      window.sessionStorage.setItem(localStorageKeys.USER_EDIS_DETAILS, checkenableEncrypt(nonPoaData));
    }
  }

  if (response.response.infoID === 'EGN011' || response.response.infoID === 'EGN012') {
    return response;
  }
  if (response.response.infoID !== "0") {

    var msg = response.response.infoMsg;
    var error = new Error(msg);
    error.infoID = response.response.infoID;
    throw error;
  }
  return response;
}

//===================================================================